<app-header [showBackButton]="true" (goBackEvent)="goBack()" [darkMode]="true"></app-header>

<section class="container text-center">
  <app-questions-progress [id]="activeQuestionId"></app-questions-progress>

  <app-about-app class="step" [ngClass]="{'active': activeQuestionId === 1}"
                 (continueEvent)="continue($event)"></app-about-app>

  <app-quiz class="step" [ngClass]="{'active': activeQuestionId === 2}"
            (continueEvent)="continue($event)"></app-quiz>

  <app-dating-goal class="step" [ngClass]="{'active': activeQuestionId === 3}"
                   (continueEvent)="continue($event)"></app-dating-goal>

  <app-how-open class="step" [ngClass]="{'active': activeQuestionId === 4}"
                (continueEvent)="continue($event)"></app-how-open>

  <app-anal-sex class="step" [ngClass]="{'active': activeQuestionId === 5}"
                (continueEvent)="continue($event)"></app-anal-sex>

  <app-group-sex class="step" [ngClass]="{'active': activeQuestionId === 6}"
                 (continueEvent)="continue($event)"></app-group-sex>

  <app-public-sex class="step" [ngClass]="{'active': activeQuestionId === 7}"
                  (continueEvent)="continue($event)"></app-public-sex>

  <app-desires class="step" [ngClass]="{'active': activeQuestionId === 8}"
               (continueEvent)="continue($event)"></app-desires>
</section>
