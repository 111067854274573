<app-header [showBackButton]="true" (goBackEvent)="goBack()" [darkMode]="true"></app-header>

<section class="container text-center">
  <h3>Please enter your email</h3>

  <form [formGroup]="form">

    <div class="control">
      <input type="text" placeholder="E-mail" formControlName="email">
    </div>

    <p class="description">We don’t share any personal <br/>information</p>

    <p class="info">
      By continuing, you agree to our
      <a href="https://elizium.co/terms-conditions" target="_blank" id="link-terms-conditions-email">Terms & Conditions</a> and <a href="https://elizium.co/privacy-policy" target="_blank" id="link-privacy-policy-email">Privacy Policy</a>.
      Have a question? Reach our support team <a href="mailto:support@elizium.co" id="link-to-support-email">here</a>.
    </p>

    <button  class="button button-primary size-normal" id="btn-get-the-app-email"
             (click)="send(form, $event)" [disabled]="!form?.valid">
      Get the App!
    </button>

  </form>
</section>
