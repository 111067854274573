import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionsFlowComponent } from './questions-flow.component';
import { SharedModule } from "../../shared.module";
import { AboutAppComponent } from './about-app/about-app.component';
import { QuizComponent } from './quiz/quiz.component';
import { DatingGoalComponent } from './dating-goal/dating-goal.component';
import { HowOpenComponent } from './how-open/how-open.component';
import { AnalSexComponent } from './anal-sex/anal-sex.component';
import { GroupSexComponent } from './group-sex/group-sex.component';
import { PublicSexComponent } from './public-sex/public-sex.component';
import { DesiresComponent } from './desires/desires.component';



@NgModule({
  declarations: [
    QuestionsFlowComponent,
    AboutAppComponent,
    QuizComponent,
    DatingGoalComponent,
    HowOpenComponent,
    AnalSexComponent,
    GroupSexComponent,
    PublicSexComponent,
    DesiresComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    QuestionsFlowComponent
  ]
})
export class QuestionsFlowModule { }
