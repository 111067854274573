import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { QuestionsModel } from "../../../models/questions-model";
import { TIME_DELAY } from "../../../models/user-model";
import { AmplitudeService } from "../../../services/amplitude.service";

@Component({
  selector: 'app-group-sex',
  templateUrl: './group-sex.component.html',
  styleUrls: ['./group-sex.component.scss']
})
export class GroupSexComponent implements OnInit {
  @Output() continueEvent = new EventEmitter<{propName: keyof QuestionsModel, data: string}>();

  constructor(
    private _renderer: Renderer2,
    private _amplitudeService: AmplitudeService
  ) { }

  ngOnInit(): void {
  }

  continue(value: string, event: any): void {
    this._renderer.addClass(event.target, 'clicked');
    this.continueEvent.emit({propName: 'groupSex' , data: value});

    this.setAmplitudeOnBtnClickEvent();

    setTimeout(() => {
      this._renderer.removeClass(event.target, 'clicked');
    }, (TIME_DELAY - 5));
  }

  setAmplitudeOnBtnClickEvent(): void {
    this._amplitudeService.trackEvent('intro3_group/role_sex_page_continue_clicked');
  }
}
