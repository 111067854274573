import { QuestionsModel } from "./questions-model";

export class UserModel {
  utmTags?: string;
  uuid?: string;
  questions: QuestionsModel;
  email?: string;
  price?: string;
  findIdeal?: boolean;

  constructor() {
    this.questions = {
      aboutApp: {id: 1},
      quiz: {id: 2},
      datingGoal: {id: 3},
      howOpen: {id: 4},
      analSex: {id: 5},
      groupSex: {id: 6},
      publicSex: {id: 7},
      desires: {id: 8}
    };
  }
}

export interface UpdateUserModel {
  donation_amount?: string;
  payment_method?: 'CREDIT_CARD' | 'PAY_PAL' | 'APPLE_PAY' | 'GOOGLE_PAY';
  trial?: 1 | 0;
}

export interface CreateUserModel {
  email: string;
  project_type: 'TEST' | 'REAL_PAYMENT';
  utm_tags?: string;
}

export const TIME_DELAY = 360;
