<img src="../../../../assets/images/steps/step-6.png" alt="">
<h3 class="question">
  Are you interested<br/>in group sex?
</h3>

<button  class="button button-secondary size-normal" (click)="continue('F + F + M', $event)"
         id="btn-ffm-group-sex">
  F + F + M
</button>
<button  class="button button-secondary size-normal" (click)="continue('M + M + F', $event)"
         id="btn-mmf-group-sex">
  M + M + F
</button>
<button  class="button button-secondary size-normal" (click)="continue('Not Interested', $event)"
         id="btn-not-interested-group-sex">
  Not Interested
</button>

<p class="info">
  Your answers are not visible to others
</p>
